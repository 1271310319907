import { postMessageToRef } from 'CasinoV2/features/overlay/helpers';
import defaultEventHandler from './default_event_handler';

export default ({ setOpen, gameRef, overlayRef }) => {
  const postToGame = postMessageToRef(gameRef);
  const defaultHandle = defaultEventHandler({ setOpen, gameRef, overlayRef });

  const openOverlay = () => {
    postToGame(JSON.stringify({
      _type: 'ucip.pause.w2gPauseCommand',
      pause: true,
    }));
    setOpen(true);
  };

  let postponeOverlay = false;
  let overlayWaiting = false;

  const events = {
    addOverlay: () => {
      if (!postponeOverlay) {
        openOverlay();
      } else {
        overlayWaiting = true;
      }
    },
    removeOverlay: () => {
      postToGame(JSON.stringify({
        _type: 'ucip.pause.w2gPauseCommand',
        pause: false,
      }));
    },
    roundStart: () => {
      postponeOverlay = true;
    },
    roundEnded: () => {
      if (overlayWaiting) {
        openOverlay();
        overlayWaiting = false;
      }
      postponeOverlay = false;
    },
    InitializationRequest: () => {
      postToGame(JSON.stringify({
        _type: 'ucip.basic.w2gInitializationResponse',
        version: '1.0.4',
        features: [
          'gameround',
          'pause',
          'balancerefresh',
        ],
      }));
    },
    CloseGameFrameCommand: () => {
      window.location = '/games/callback/lobby';
    },
    refreshBalance: () => {
      postToGame(JSON.stringify({ _type: 'ucip.balancerefresh.w2gRefreshBalanceCommand' }));
    },
  };

  return (event) => {
    let eventName = '';
    if (typeof event.data === 'string' && event.data.startsWith('{"_type"')) {
      const data = JSON.parse(event.data);
      switch (data['_type']) { /* eslint-disable-line */
        case 'ucip.basic.g2wInitializationRequest':
          eventName = 'InitializationRequest';
          break;
        case 'ucip.basic.g2wCloseGameFrameCommand':
          eventName = 'CloseGameFrameCommand';
          break;
        case 'ucip.gameround.g2wGameRoundStartNotification':
          eventName = 'roundStart';
          break;
        case 'ucip.gameround.g2wGameRoundEndNotification':
          eventName = 'roundEnded';
          break;
        default:
      }
    } else if (typeof event.data === 'string') {
      eventName = event.data;
    }

    const eventData = event.data && event.data.eventAction;
    const handle = events[eventData?.eventAction || eventData || eventName];

    if (handle) handle();

    // This is not 100% correct as the default handle will post messages
    // Those messages will be ignored
    // TODO: Probably use OOP in this specific case
    if (event.data !== 'addOverlay') defaultHandle(event);
  };
};
