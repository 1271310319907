import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { getEventHandler } from './helpers';

const Overlay = ({
  url, provider, gameRef, platformId,
}) => {
  const [isOpen, setOpen] = useState(false);
  const overlayRef = useRef(null);

  // Effect for listening to messages
  useEffect(() => {
    const parseMessage = getEventHandler(provider, platformId)({
      setOpen,
      gameRef,
      overlayRef,
    });

    window.addEventListener('message', parseMessage, false);
    return () => window.removeEventListener('message', parseMessage);
  }, []);

  // Effect to handle focus when overlay opens/closes
  useEffect(() => {
    const { activeElement } = document;

    if (isOpen) {
      activeElement.blur();
      gameRef?.current?.blur();
      overlayRef?.current?.focus();
    }

    return () => {
      if (activeElement instanceof HTMLElement) {
        activeElement.focus();
      }
    };
  }, [isOpen]);

  return (
    <iframe
      tabIndex={-1}
      src={url}
      className={classnames('gamePopup-message', { open: isOpen })}
      ref={overlayRef}
    />
  );
};

Overlay.propTypes = {
  url: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  gameRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  platformId: PropTypes.number,
};

Overlay.defaultProps = {
  platformId: null,
};

export default Overlay;
