import { postMessageToRef } from 'CasinoV2/features/overlay/helpers';
import defaultEventHandler from './default_event_handler';

export default ({ setOpen, gameRef, overlayRef }) => {
  const postToGame = postMessageToRef(gameRef);
  const defaultHandle = defaultEventHandler({ setOpen, gameRef, overlayRef });

  const events = {
    addOverlay: () => {
      postToGame({
        method: 'pauseGame',
        params: {
          callback: 'gamePausedHandler',
          return_data: ['session_id'],
        },
      });
    },
    removeOverlay: () => {
      postToGame({
        method: 'resumeGame',
        params: {},
      });
    },
    gameLoadedHandler: () => {
      postToGame({
        method: 'confirmHandshake',
        params: {
          success: 'handshakeSuccess',
          fail: 'handshakeFail',
        },
      });
    },
    handshakeSuccess: () => {
      defaultHandle({ data: 'gameReady' });
    },
  };

  return (event) => {
    const handle = events[event.data] || events[event.data.method];
    if (handle) handle();

    // This is not 100% correct as the default handle will post messages
    // Those messages will be ignored
    // TODO: Probably use OOP in this specific case
    defaultHandle(event);
  };
};
