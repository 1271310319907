import defaultEventHandler from './default_event_handler';

export default ({ setOpen, gameRef, overlayRef }) => {
  const defaultHandle = defaultEventHandler({ setOpen, gameRef, overlayRef });

  const events = {
    addOverlay: () => {
      if (window.regal) window.regal.realityCheck.realityCheckShown();
    },
    removeOverlay: () => {
      if (window.regal) window.regal.realityCheck.realityCheckAcknowledged();
    },
  };

  return (event) => {
    const handle = events[event.data];
    if (handle) handle();

    // This is not 100% correct as the default handle will post messages
    // Those messages will be ignored
    // TODO: Probably use OOP in this specific case
    defaultHandle(event);
  };
};
