import { postMessageToRef } from 'CasinoV2/features/overlay/helpers';
import defaultEventHandler from './default_event_handler';

export default ({ setOpen, gameRef, overlayRef }) => {
  let messageSource;
  let postponeOverlay = false;
  let overlayWaiting = false;
  const postToOverlay = postMessageToRef(overlayRef);
  const postToGame = (message) => {
    if (!messageSource) {
      postMessageToRef(gameRef)(message);
    } else {
      messageSource.postMessage(message, '*');
    }
  };

  const defaultHandle = defaultEventHandler({ setOpen, gameRef, overlayRef });

  const openOverlay = () => {
    postToGame({ command: 'EVO:STOP_AUTOPLAY' });
    setOpen(true);
  };

  const events = {
    'EVO:APPLICATION_READY': (message) => {
      postToOverlay('gameReady');
      messageSource = (message && message.source) ? message.source : null;
      postToGame({
        command: 'EVO:EVENT_SUBSCRIBE',
        event: 'EVO:GAME_LIFECYCLE',
      });
    },
    addOverlay: () => {
      if (!postponeOverlay) {
        openOverlay();
      } else {
        overlayWaiting = true;
      }
    },
    'EVO:GAME_LIFECYCLE': (message) => {
      if (message.data.interruptAllowed) {
        if (overlayWaiting) {
          openOverlay();
          overlayWaiting = false;
        }
        postponeOverlay = false;
      } else {
        postponeOverlay = true;
      }
    },
    refreshBalance: () => {
      postToGame({
        command: 'EVO:REFRESH_BALANCE',
      });
    },
  };

  return (event) => {
    const eventData = event.data;
    const handle = events[eventData?.eventAction || eventData || eventData?.event];

    if (handle) handle(event);

    // This is not 100% correct as the default handle will post messages
    // Those messages will be ignored
    // TODO: Probably use OOP in this specific case
    if (event.data !== 'addOverlay') defaultHandle(event);
  };
};
