import { postMessageToRef } from 'CasinoV2/features/overlay/helpers';
import { setFragment, mainContextBack } from 'bv-helpers/location';
import { v as bvVar } from 'bv';

export default ({ setOpen, gameRef, overlayRef }) => {
  const postToOverlay = postMessageToRef(overlayRef);
  const postToGame = postMessageToRef(gameRef);

  // Message handlers
  const openOverlay = () => { setOpen(true); };
  const closeOverlay = () => { setOpen(false); };

  const gameReady = () => { postToOverlay('gameReady'); };
  const overlayReady = () => { postToGame('overlayReady'); };

  const addOverlay = () => {
    postToGame('stopGame');
    openOverlay();
  };

  const removeOverlay = () => {
    postToGame('restartGame');
    closeOverlay();
  };

  const openTransaction = () => { setFragment('/bv_cashier/history/1'); };

  const seeWallets = () => {
    // Zaynar needs this message because the offers can be in a different tab.
    // Now the casino overlay could do this directly
    // To be fixed in casino overlay when zaynar is deprecated
    window.open(`/${bvVar('locale')}/offer/wallets`);
  };

  const openSaferGambling = () => {
    window.open(`/${bvVar('locale')}/safer_gambling/portal`);
  };

  const openStakeLimits = () => {
    window.open(`/${bvVar('locale')}/safer_gambling/portal/stake_limits`);
  };

  const openLossLimits = () => {
    window.open(`/${bvVar('locale')}/safer_gambling/portal/loss_limits`);
  };

  const refreshBalance = (refreshedBalance) => {
    postToGame({
      type: 'updateBalance',
      balance: refreshedBalance,
    });
  };

  const events = {
    gameReady,
    overlayReady,
    addOverlay,
    removeOverlay,
    openTransaction,
    closePopup: mainContextBack,
    showNotieMessage: openOverlay,
    hideNotieMessage: closeOverlay,
    seeWallets,
    openSaferGambling,
    openStakeLimits,
    openLossLimits,
    refreshBalance,
  };

  return (event) => {
    const eventData = event.data;
    const eventDataRefreshedBalance = eventData?.refreshedBalance;
    const handle = events[eventData?.eventAction || eventData];

    if (handle) handle(eventDataRefreshedBalance || null);
  };
};
