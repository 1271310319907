import { postMessageToRef } from 'CasinoV2/features/overlay/helpers';
import defaultEventHandler from './default_event_handler';

export default ({ setOpen, gameRef, overlayRef }) => {
  const postToGame = postMessageToRef(gameRef);
  const defaultHandle = defaultEventHandler({ setOpen, gameRef, overlayRef });

  const openOverlay = () => {
    postToGame('StopGamePlay');
    setOpen(true);
  };

  let postponeOverlay = false;
  let overlayWaiting = false;

  const events = {
    addOverlay: () => {
      if (!postponeOverlay) {
        openOverlay();
      } else {
        overlayWaiting = true;
      }
    },
    removeOverlay: () => { postToGame('ReStartGamePlay'); },
    gameBusy: () => {
      postponeOverlay = true;
    },
    gameNotBusy: () => {
      if (overlayWaiting) {
        openOverlay();
        overlayWaiting = false;
      }
      postponeOverlay = false;
    },
  };

  return (event) => {
    const handle = events[event.data] || events[event.data.method] || events[event.data.event];
    if (handle) handle();

    // This is not 100% correct as the default handle will post messages
    // Those messages will be ignored
    // TODO: Probably use OOP in this specific case
    if (event.data !== 'addOverlay') defaultHandle(event);
  };
};
