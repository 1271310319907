import { postMessageToRef } from 'CasinoV2/features/overlay/helpers';
import defaultEventHandler from './default_event_handler';

export default ({ setOpen, gameRef, overlayRef }) => {
  const postToGame = postMessageToRef(gameRef);
  const postToOverlay = postMessageToRef(overlayRef);
  const defaultHandle = defaultEventHandler({ setOpen, gameRef, overlayRef });

  const events = {
    gameReady: () => { postToOverlay('gameReady'); },
    addOverlay: () => {
      postToGame({
        type: 'popupDisplayed',
      });
    },
    removeOverlay: () => {
      postToGame({
        type: 'popupClosed',
      });
    },
  };

  return (event) => {
    const handle = events[event.data] || events[event.data.type];
    if (handle) handle();

    // This is not 100% correct as the default handle will post messages
    // Those messages will be ignored
    // TODO: Probably use OOP in this specific case
    defaultHandle(event);
  };
};
