import { camelize } from 'underscore.string';
import * as eventHandlers from '../event_handlers';

const { defaultEventHandler, ...eventHandlersByProvider } = eventHandlers;

const getProviderByPlatform = (platformId) => {
  switch (platformId) {
    case 42: return 'redtiger';
    case 49: return 'pariplay';
    default: return 'generic';
  }
};

export default (provider, platformId) => {
  let providerName = provider;
  if (provider === 'generic') {
    providerName = getProviderByPlatform(platformId);
  }
  return eventHandlersByProvider[`${camelize(providerName)}EventHandler`] || defaultEventHandler;
};
